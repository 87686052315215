import { BusinessCenterTwoTone, VisibilityTwoTone } from '@mui/icons-material';
import { Color, COLORS, TYPOGRAPHY, UseMediaQuery } from '@sortlist-frontend/design-system';
import { TFunction } from '@sortlist-frontend/translation/ssr';
import { ReactElement } from 'react';

import { GetUrlType } from '_core/links/public-app-links';

import { OpportunityIllustration } from './components/OpportunityIllustration';
import { VisibilityIllustration } from './components/VisibilityIllustration';

export type PricingModule = {
  id: string;
  icon: ReactElement;
  name: string;
  title: string;
  description: string;
  ctaLabel: string;
  palette: Color;
  illustration: ReactElement | string;
  inverted: boolean;
  items: string[];
  ctaLink: string;
};

export const getPricingModules = (t: TFunction, media: UseMediaQuery['media'], getUrl: GetUrlType): PricingModule[] => {
  return [
    {
      id: 'visibilityModule',
      icon: <VisibilityTwoTone style={{ fontSize: TYPOGRAPHY.H6 }} />,
      name: t('providers:modules.visibility.name'),
      title: t('providers:modules.visibility.title_v1'),
      description: t('providers:modules.visibility.description_v1'),
      ctaLabel: t('providers.learnMore'),
      palette: 'danger',
      illustration: (
        <VisibilityIllustration agencyIndex={0} bgColor={media.xs ? COLORS.NEUTRAL_100 : COLORS.SECONDARY_100} />
      ),
      inverted: true,
      items: [
        t('providers:modules.visibility.items_v1.0'),
        t('providers:modules.visibility.items_v1.1'),
        t('providers:modules.visibility.items_v1.2'),
      ],
      ctaLink: getUrl('providers.visibility'),
    },
    {
      id: 'opportunityModule',
      icon: <BusinessCenterTwoTone style={{ fontSize: 20 }} />,
      name: t('providers:modules.opportunity.name'),
      title: t('providers:modules.opportunity.title_v1'),
      description: t('providers:modules.opportunity.description'),
      ctaLabel: t('providers.learnMore'),
      palette: 'success',
      illustration: <OpportunityIllustration />,
      inverted: false,
      items: [
        t('providers:modules.opportunity.items.0'),
        t('providers:modules.opportunity.items.1'),
        t('providers:modules.opportunity.items.2'),
      ],
      ctaLink: getUrl('providers.opportunity'),
    },
  ];
};

export type FakeOpportunity = {
  id: string;
  title: string;
  location: string;
  service: string;
};

export const getFakeOpportunities = (t: TFunction): FakeOpportunity[] => {
  return [
    {
      id: 'webDevelopment',
      title: t('providers:modules.opportunity.fake.webWanted'),
      location: t('common:fakeLocations.paris'),
      service: t('common:expertises.webDevelopment.name'),
    },
    {
      id: 'rocketCompany',
      title: t('providers:modules.opportunity.fake.rocketCompany'),
      location: t('common:fakeLocations.brussels'),
      service: t('common:expertises.webDevelopment.name'),
    },
    {
      id: 'foodCompany',
      title: t('providers:modules.opportunity.fake.foodCompany'),
      location: t('common:fakeLocations.amsterdam'),
      service: t('common:expertises.advertising'),
    },
  ];
};
